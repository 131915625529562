.search_results {
  position: fixed;
  top: 60px;
  right: var(--fluid-margin);
  width: var(--modal-box-dropdown-width);
  text-align: left;
  background: var(--color-white);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px var(--radius-lg) var(--radius-lg);
}

.search_results__item {
  position: relative;
  margin: 0;
  padding: 0;
}
.search_results__item:not(:last-child) {
  border-bottom: 1px solid var(--color-gray-4);
}

.search_results__item img {
  position: absolute;
  left: 12px;
  top: 14px;
  width: auto;
  height: 10px;
  margin: 0;
}
.search_results__item:first-child img {
  top: 16px;
}

.search_results__item a {
  display: block;
  margin: 0;
  padding: 10px 0 10px 26px;
  text-decoration: none;
  color: var(--color-blue-1);
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 125%;
}
.search_results__item:first-child a {
  padding-top: 12px;
}
.search_results__item a:hover {
  background-color: var(--color-blue-1);
  color: var(--color-white);
}
.search_results__item:hover:last-child a {
  border-radius: 0 0 calc(var(--radius-lg) - 3px) calc(var(--radius-lg) - 3px);
}
