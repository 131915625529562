.dropdown_button {
  position: absolute;
  top: -1px;
  right: 0;
  width: 50px;
  height: inherit;
  background: linear-gradient(
    90deg,
    rgba(247, 249, 253, 0) 0%,
    var(--color-blue-4) 46.88%
  );
  border: none;
  margin: 0;
  padding: 0;
  border-radius: var(--radius-sm);
  cursor: pointer;
}
/* SAFARI FIX */
@supports (-webkit-hyphens: none) {
  .dropdown_button {
    width: 36px;
    background: var(--color-blue-4);
  }
}

.dropdown_icon {
  display: block;
  width: 15px;
  height: 15px;
  float: right;
  margin-right: 10px;
  transition: transform 0.2s linear;
  cursor: pointer;
}
