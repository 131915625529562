.select_component__container {
  position: relative;
  display: block;
  width: var(--select-component-width);
  min-width: var(--dropdown-width);
  margin-top: 0.5rem;
}

.select_component__label {
  display: block;
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--color-gray-3);
}

.select_component__trash_icon {
  position: absolute;
  top: 9px;
  right: 32px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.trash_icon_disabled {
  display: none;
}

.trash_icon__color {
  fill: var(--color-gray-6);
}

@media screen and (max-width: 560px) {
  .select_component__trash_icon {
    top: 10px;
    right: 38px;
    width: 21px;
    height: 21px;
  }
  .dropdown_sm {
    width: 185px !important;
  }
  .dropdown_lg {
    width: 270px !important;
  }
}
