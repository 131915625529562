.destination_item {
  display: flex;
  flex-direction: column;
}

.destination_item__toggle {
  cursor: pointer;
}

.destination_item__toggle svg {
  display: inline;
  width: 1rem;
  height: auto;
  transition: transform 0.2s linear;
  cursor: pointer;
}

.destination_item__asterisk {
  font-size: 1rem;
  padding-right: 2px;
  color: var(--color-blue-1);
}
