.deals__container,
.deals__container_static {
  position: relative;
  display: block;
  background: var(--color-blue-4);
  padding: 0.65rem;
  margin: 0;
  border-bottom: 1px solid var(--color-gray-4);
}
.deals__container:hover,
.deals__container_static:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.deals__header {
  display: flex;
  justify-content: space-between;
  text-decoration: none;
}

.deals__title {
  margin: 0 1rem 5px 0;
  font-weight: 600;
  font-size: 1.5rem;
  color: var(--color-gray-3);
  text-decoration: none;
}

.deals__title:hover {
  text-decoration: underline;
}

.deals__price {
  margin: 0;
  padding: 0;
  flex-shrink: 0;
  font-weight: 400;
  font-size: var(--price-height);
  color: var(--color-orange-1);
}

.deals__tags {
  width: calc(100% - 80px);
  overflow: visible;
  position: relative;
}

.deals__tags ul {
  display: flex;
  flex-wrap: wrap;
  margin-left: -2px;
  height: calc(2rem - 2px);
  width: calc(100% + 80px + 1rem);
  overflow: hidden;
}
.deals__tags ul::before {
  content: "";
  background: linear-gradient(90deg, transparent, var(--color-blue-4));
  width: calc(74px + 1rem);
  height: 100%;
  position: absolute;
  top: 0;
  right: -1px;
  transform: translateX(100%);
}
/* SAFARI FIX */
@supports (-webkit-hyphens: none) {
  .deals__tags ul::before {
    display: none;
  }
}

.deals__tags a {
  padding: 0;
  margin: 1px 0;
  text-decoration: none;
}

.deals__content {
  position: relative;
  display: block;
  margin: 5px 0 0;
  text-decoration: none;
}

.deals__text {
  max-height: 2.5rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 125%;
  color: var(--color-gray-3);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.deals__footer {
  display: flex;
  justify-content: space-between;
  margin: 3px 0 0;
  padding: 0;
}

.deals__footer_lm {
  margin: 10px 2px 10px 0 !important;
}

.deals__time,
.deals__valid_until {
  font-size: 1rem;
  font-weight: 400;
  color: var(--color-blue-1);
}
