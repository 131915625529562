.deal__container {
  padding: 0.75rem 0.75rem 0;
  margin: 0;
  background: var(--color-blue-4);
  border: 1px solid var(--color-blue-5);
  border-radius: var(--radius-sm);
}

.deal__title {
  margin: 0;
  font-weight: 600;
  font-size: 1.65rem;
  color: var(--color-gray-3);
}

.deal__tags ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -2px;
  margin-top: 0.5rem;
}

.deal__tags li {
  margin: 1px 0;
}

.deal__tags a {
  text-decoration: none;
}

.deal__text {
  margin: 0.75rem 0;
  padding: 0;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 150%;
  color: var(--color-gray-3);
}

.deal__text p,
.deal__text a,
.deal__text a b {
  font: inherit;
}

.deal__text a b {
  font-weight: bold;
}

.deal__text ul {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 2rem;
  font: inherit;
}

.deal__text ul li {
  list-style: disc;
  font: inherit;
}

.deal__button {
  text-align: center;
}

.deal__button a {
  display: inline-block;
  text-decoration: none;
  font-size: 1.25rem;
  font-weight: 600;
  padding: 1rem 2.5rem;
  margin: 0 0 0.75rem;
  color: var(--color-white);
  background-color: var(--color-green-2);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: var(--radius-sm);
}
.deal__button a:hover {
  background-color: var(--color-green-5);
}

.deal__image {
  width: 100%;
  border: 1px solid var(--color-gray-5);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: var(--radius-sm);
}

.deal__prices_info_container {
  border-radius: var(--radius-sm);
  position: relative;
}

.deal__prices_info {
  padding: 2px 0.65rem 1px;
  margin: 4px 0 0;
  font-size: 0.9rem;
  text-align: center;
  color: var(--color-gray-2);
  background: var(--color-white);
  border-radius: var(--radius-sm);
}

.deal__lastdeals {
  margin: 6px -0.75rem 0;
}

.deal__lastdeal {
  display: block;
  padding: 0.65rem 0.75rem;
  cursor: pointer;
  background-color: var(--color-blue-4);
  border-top: 1px solid var(--color-blue-5);
  text-decoration: none;
  position: relative;
  overflow: hidden;
}

.deal__lastdeal:last-child {
  border-radius: 0 0 calc(var(--radius-sm) - 1px) calc(var(--radius-sm) - 1px);
}

.deal__lastdeal:hover:hover {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.deal__lastdeal_title {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 1.5rem;
  color: var(--color-gray-3);
}

.deal__lastdeal_title > span {
  display: inline-block;
  font: inherit;
  max-width: calc(100% - 17px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.deal__lastdeal_ago {
  margin: 0;
  padding: 0;
  color: var(--color-blue-1);
}

@media screen and (max-width: 800px) {
  .deal__lastdeal_title {
    font-weight: 500;
    font-size: 1.25rem;
  }
  .deal__lastdeal_title > span {
    max-width: calc(100% - 15px);
  }
}

@media screen and (max-width: 560px) {
  .deal__lastdeal_title {
    font-size: 1.2rem;
  }
  .deal__lastdeal_title > span {
    max-width: calc(100% - 13px);
  }

  .deal__prices_info_container {
    cursor: pointer;
  }

  .dpic_minimized {
    max-height: 59px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
  }
}
